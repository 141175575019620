var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{staticClass:"label",attrs:{"label":"Nombre","label-for":"input-name","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-name","name":"input-name","state":_vm.validateState('name'),"aria-describedby":"input-name-live-feedback","size":"sm"},model:{value:(_vm.$v.methodological_strategy.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.methodological_strategy.name, "$model", $$v)},expression:"$v.methodological_strategy.name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[_vm._v("Este campo debe tener al menos 5 caracteres.")])],1),_c('b-form-group',{staticClass:"label",attrs:{"label":"Descripción","label-for":"input-description","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-textarea',{attrs:{"id":"input-description","name":"input-description","state":_vm.validateState('description'),"aria-describedby":"input-description-live-feedback","size":"md","rows":"3","max-rows":"8"},model:{value:(_vm.$v.methodological_strategy.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.methodological_strategy.description, "$model", $$v)},expression:"$v.methodological_strategy.description.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-description-live-feedback"}},[_vm._v("Este campo debe tener al menos 5 caracteres.")])],1),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-for":"input-is_active","label-cols":"0","label-cols-sm":"3","label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"block-title-container"},[_c('div',[_vm._v("Vigencia")]),_c('div',{staticClass:"ml-2"},[_c('InfoTooltip',{attrs:{"tooltip_text":`Al estar vigente la ${_vm.$getVisibleNames(
              'mesh.methodologicalstrategy',
              false,
              'Estrategia Metodológica'
            )} será visible en ${_vm.$getVisibleNames(
              'manual.matriz_2',
              false,
              'Matriz 2'
            )}.`}})],1)])]},proxy:true}])},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"size":"md"},model:{value:(_vm.methodological_strategy.is_active),callback:function ($$v) {_vm.$set(_vm.methodological_strategy, "is_active", $$v)},expression:"methodological_strategy.is_active"}})],1),(!isNaN(_vm.methodological_strategy.id))?_c('b-form-group',{staticClass:"p-0 m-0 mt-2 mb-2",attrs:{"label-for":"input-modification-date","label-cols":"0","label-cols-sm":"0"}},[_c('ModificationDate',{attrs:{"Information":_vm.methodological_strategy}})],1):_vm._e(),_c('div',{staticClass:"row"},[(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }